.banner {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 85px;
    align-items: center;
}

.globeDemo {
    display: flex;
}

.bannerBlack {
    overflow: hidden;
    z-index: 2;
    display: flex;
    width: 100%;
}

.bannerColor {
    border: 2px solid #2A2F3A;
    border-left: none;
    padding-right: 20px;
    height: 600px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
    background-color: #08090c;
    border-radius: 0px 10px 10px 0px;
    transform: translateX(100%);
    animation: slideIn 1s ease forwards;
    transition: border 0.4s ease-in-out;
}

.bannerColor:hover {
    border: 2px solid #3d4454;
    border-left: none;
}

.bannerLastName {
    height: 300px;
    margin-top: -38px;
    font-family: 'Oswald', sans-serif;
    font-size: 92px;
    font-weight: 400;
}

.bannerFirstName {
    padding-left: 20px;
    margin-top: -20px;
    font-family: 'Oswald', sans-serif;
    font-size: 92px;
    font-weight: 400;
    margin-left: -4px;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.bannerBlack {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 600px;
    background-color: #08090c;
    width: 100%;
    border: 2px solid #2A2F3A;
    border-right: none;
    margin-left: 20px;
    border-radius: 10px 0px 0px 10px;
    transition: border 0.4s ease-in-out;
}

.bannerBlack:hover {
    border: 2px solid #3d4454;
    border-right: none;
}


.bannerContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
}



h1 {
    padding-left: 20px;
    margin: 0px;
    width: fit-content;
    font-size: 30px;
    font-weight: 400;
}

.bannerAbout {
    margin-left: 20px;
    font-weight: 300;
    font-size: 22px;
    width: 290px;
}

.bannerLinks {
    margin-top: 15px;
    padding-left: 20px;
    display: flex;
}

.bannerLink {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    transition: 0.3s ease;
    background-color: white;
    width: 38px;
    height: 38px;
    border-radius: 50%;
}

.bannerLink:hover {
    transform: translateY(-5%);
}

.githubLink {
    width: 51px;
    background-color: inherit;
}

.bannerContact {
    color: #12141C;
    font-size: 28px;
}

.imgContact {
    width: 51px;
    border-radius: 50%;
}


@media screen and (max-width: 991px) {

    .banner {
        padding-left: 5%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .bannerColor {
        justify-content: flex-start;
        border-radius: 10px 0px 0px 10px;
        margin-bottom: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0px;
        height: fit-content;
        width: 100%;
        border-left: 2px solid #2A2F3A;
        border-right: none;
    }

    .bannerColor:hover {
        border-left: 2px solid #3d4454;
        border-right: none;
    }

    @keyframes slideIn {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }

        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }

    .bannerBlack {
        margin: 0;
        height: fit-content;
        padding: 20px 0;
    }

    .bannerFirstName {
        padding-top: 0px;
        margin-top: -30px;
        margin-bottom: -20px;
    }

    .bannerLastName {
        height: fit-content;
        padding-top: 0px;
        padding-left: 20px;
        margin-bottom: -20px;
        margin-top: -30px;
        text-align: start;
    }


    h1 {
        margin-top: 10px;
    }

    .bannerLinks {
        margin-top: 10px;
    }
}

@media screen and (max-width: 635px) {
    .banner {
        padding-left: 5%;
        margin-top: 52px;
    }

    .bannerColor {
        margin-bottom: 10px;
        border-radius: 5px 0px 0px 5px;
    }

    .bannerFirstName {
        font-size: 60px;
        margin-top: -20px;
        margin-bottom: -20px;
        padding-left: 13px;
    }

    .bannerLastName {
        margin-top: -25px;
        font-size: 60px;
        padding-left: 20px;
    }

    .bannerBlack {
        border-radius: 5px 0px 0px 5px;
        padding-top: 20px;
        padding-bottom: 20px;
    }


    .bannerContent {
        height: 210px;
        margin-left: 10px;
    }

    .bannerAbout {
        width: 225px;
        font-size: 17px;
        margin-left: 10px;
    }

    h1 {
        margin-top: 20px;
        font-size: 22px;
        padding-left: 10px;
        margin-bottom: 15px;
    }

    .bannerLinks {
        margin-top: 20px;
        padding-left: 10px;
    }

    .bannerLink {
        margin-right: 10px;
        width: 30px;
        height: 30px;
    }

    .bannerContact {
        color: black;
        font-size: 20px;
    }

    .imgContact {
        width: 40px;
        border-radius: 50%;
    }

    .githubLink {
        width: 40px;
        background-color: inherit;
    }
}