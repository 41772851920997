.carousel {
    padding-top: 20px;
    margin: auto;
    margin-top: 10px;
    width: 80%;
    height: 400px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    background: #08090c;
    border: 2px solid #2A2F3A;
    border-radius: 5px;
    transition: all 0.4s ease-in-out;
}

.carousel:hover {
    border: 2px solid #3d4454;
}


.carouselTitle {
    width: 80%;
    margin: auto;
    font-size: 22px;
    margin-top: 20px;
    font-weight: 400;
}

.carouselContainer {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 100%;
}

.carouselInner {
    position: relative;
    display: flex;
    justify-content: center;
    width: 360px;
}

.carouselItem {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
    width: 220px;
}

.carouselItem img {
    width: fit-content;
    height: 360px;
    border-radius: 10px;
}

.carouselItem.active {
    opacity: 1;
    transform: translateX(0) scale(1);
    z-index: 2;
}

.carouselItem.next {
    opacity: 0.5;
    transform: translateX(40%) scale(0.9);
    z-index: 1;
}

.carouselItem.prev {
    opacity: 0.5;
    transform: translateX(-40%) scale(0.9);
    z-index: 1;
}


.carouselDescriptions {
    display: flex;
    align-items: center;
    height: 100%;
}

.carouselDescription {
    font-size: 17px;
    font-weight: 300;
    position: absolute;
    width: 25%;
    right: 15%;
}

.carouselDescription.show {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.carouselDescription.hide {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}


.carouselDescription.prev {
    opacity: 0;
    transform: translateX(-40%) scale(0.9);
    z-index: 1;
}

.carouselControl {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 43%;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    cursor: pointer;
    z-index: 3;
    transition: all 0.2s ease-in-out;
    color: #CCCCCC;
}


.carouselControl:hover {
    color: rgb(115, 115, 115);
}


.carouselControl.prev {
    left: -40px;
}

.carouselControl.next {
    right: -40px;
}

.carouselDescriptionsMobile {
    display: none;
}


@media screen and (max-width: 1100px) {

    .carousel {
        height: 500px;
    }


    .carouselContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .carouselDescription {
        text-align: center;
        position: absolute;
        width: 90%;
        right: 5%;
        top: 395px;
    }

    .carousel h4 {
        font-size: 14px;
    }

    .carouselControl {
        top: 35%
    }

    ;

}

@media screen and (max-width: 991px) {

    .carousel {
        width: 90%;
    }

    .carouselTitle {
        width: 90%;
    }
}



@media screen and (max-width: 600px) {

    .carousel {
        height: 620px;
        margin-top: 10px;
    }

    .carouselTitle {
        font-size: 18px;
    }

    .carouselContainer {
        width: 100%;
    }

    .carouselInner {
        width: 100%;
    }

    .carouselItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    .carouselItem img {
        width: 230px;
        height: 410px;
    }


    .carouselItem.next {
        opacity: 0;
    }

    .carouselItem.prev {
        opacity: 0;
    }

    .carouselControl {
        top: 33%;
    }

    .carouselControl.prev {
        left: 2%;
    }

    .carouselControl.next {
        right: 2%;
    }

    .carouselDescriptions {
        display: none;
    }

    .carouselDescription {
        display: none;
    }

    .carouselDescriptionMobile {
        font-weight: 300;
        font-size: 16px;
        text-align: center;
    }

    .carouselDescriptionsMobile {
        margin-top: 10px;
        width: 100%;
        display: flex;
    }

    .carouselDescriptionMobile.show {
        display: flex;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }

    .carouselDescriptionMobile.hide {
        display: none;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }





}