.contact {
    border-top: 2px solid #2A2F3A;
    background-color: #08090c;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 550px;
}

.contactContentBorder {
    width: 100%;
    box-sizing: border-box;
    border-left: solid #08090c 4px;
    border-right: solid #08090c 4px;
}

.myEmailLink {
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 400;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    align-items: center;
    background: #2A2F3A;
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    width: 100%;
    transition: transform 0.3s ease;
}

.myEmailLink:hover {
    transform: translateY(-3px);
}

.linkName {
    margin: 15px;
}

.material-symbols-outlined {
    margin: 15px;
    font-size: 35px;
}

.contactForm {
    flex-direction: column;
    margin: auto;
    display: flex;
    width: 100%;
    justify-content: center;
}

.contact h3 {
    box-sizing: border-box;
    width: 100%;
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 400;
}

form {
    margin: auto;
    width: 100%;
}

input,
textarea {
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    font-size: 15px;
    font-family: 'Poppins';
    border-radius: 10px;
    border: 4px solid #101219;
    width: 100%;
    transition: border 0.3s ease;
}

label {
    padding-left: 4px;
    font-size: 18px;
    font-weight: 400;
}

input {
    height: 50px;
    margin-bottom: 10px;
    font-weight: 300;
}

textarea {
    resize: none;
    height: 140px;
    margin-bottom: 20px;
    font-weight: 300;
}

textarea:focus,
input:focus {
    border: 2px solid #5e6472;
    border-width: 4px;
    outline: none;
}

.contactMail {
    width: 100%;
}

.contactMsg {
    display: flex;
    flex-direction: column;
}

.contactButton {
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Oswald';
    display: flex;
    align-items: center;
    margin: auto;
    padding: 3px 10px 5px;
    margin-bottom: 20px;
    border: 2px solid #CCCCCC;
    background-color: #101219;
    font-size: 20px;
    font-weight: 200;
    text-decoration: none;
    transition: 0.3s ease;
}

.contactButton:hover {
    border: 2px solid #aaacbe;
    background-color: #aaacbe;
    color: #101219;
}

.alertMessage {
    font-size: 15px;
    width: fit-content;
    margin: auto;
    background-color: #636374;
    padding: 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.alertMessage.show {
    opacity: 1;
}

@media screen and (max-width: 991px) {
    .contactForm p {
        font-size: 30px;
    }
}

@media screen and (max-width: 635px) {
    .contactContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    .myEmailLink {
        font-size: 20px;
    }

    .linkName {
        margin: 15px;
    }

    .material-symbols-outlined {
        margin: 15px;
    }

    .material-symbols-outlined {
        font-size: 30px;
    }

    .contact h3 {
        margin-top: 35px;
        margin-bottom: 10px;
        font-size: 18px;
    }

    .contactButton {
        font-size: 15px;
    }

    label {
        font-size: 15px;
    }

    .alertMessage {
        font-size: 13px;
        padding: 7.2px 10px;
    }
}