.projectContent {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 48%;
    margin-bottom: 30px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0;
}

.perso {
    opacity: 1;
}

.show-projects .projectContent {
    opacity: 1;
}

.overlay h3 {
    font-weight: 400;
    font-size: 18px;
    margin: 0px;
    transition: transform 0.5s ease;
    transform: translateY(-150%);
}

.overlay {
    background: rgba(15, 15, 15, 0.65);
    backdrop-filter: blur(2px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 20px;
    transition: opacity 0.5s ease;
}

.overlay:hover {
    opacity: 1;
}

.overlay:hover h3 {
    transform: translateY(0%);
}

.overlayTitle {
    border-radius: 10px;
    display: flex;
    align-content: flex-end;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    position: absolute;
    transition: transform 0.3s ease;
}

.overlayTitle p {
    margin: 0;
    font-weight: 300;
    font-size: 14px;
}

.overlayTitle h2 {
    margin-top: 0;
    font-weight: 400;
    font-size: 16px;
}

.projectTitle {
    background-color: #101219e6;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    margin: 0 0 10px 10px;
    background-color: #101219e6;
}

.projectLink {
    height: 100%;
    opacity: 0;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 100%;
    display: flex;
    position: relative;
}

.projectImg {
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
}

.projectDisplay {
    display: flex;
    width: 100%;
}

@media screen and (max-width: 991px) {
    .projectContent {
        width: 100%;
        border-radius: 0px;
    }

    .projectLink {
        width: 90%;
    }

}

@media screen and (max-width: 635px) {
    .projectContent {
        margin-bottom: 20px;
    }

    .projectImg {
        border-radius: 5px;
    }

    .overlay h3 {
        font-size: 15px;
    }

    .overlay {
        border-radius: 5px;
    }
}