#project {
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.buttonFilter {
    z-index: 10;
    cursor: pointer;
    border-style: none;
    color: #CCCCCC;
    background-color: #08090c;
    font-size: 22px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 2px;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
    border-bottom: solid 2px #08090c;
    transition: color 0.2s ease-in-out;
}

.buttonFilter:hover {
    color: white;
}


.filter {
    border: 2px solid #2A2F3A;
    opacity: 0;
    border-radius: 5px 5px;
    background-color: #08090c;
    padding: 30px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: border 0.4s ease-in-out;
}

.filter:hover {
    border: 2px solid #3d4454;
}

.allProject {
    opacity: 0;
    padding-top: 30px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    transition: opacity 0.3s ease-in-out;
}

.allProject.show-projects {
    opacity: 0.8;
}

.filterActive {
    color: white;
    background-color: #08090c;
    font-size: 22px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 2px;
    font-family: 'Oswald', sans-serif;
    transition: 0.4s ease;
}

@media screen and (max-width: 991px) {
    #project {
        width: 100%;
    }

    .filter {
        margin: auto;
        width: 90%;
        border-radius: 10px;
    }

    .allProject {
        padding-bottom: 20px;
        border-radius: 0px;
        width: 100%;
        flex-direction: column;
    }
}

@media screen and (max-width: 635px) {
    .filter {
        padding: 20px 0px;
        border-radius: 5px;
    }

    .buttonFilter {
        font-size: 18px;
    }

    .allProject {
        padding-top: 20px;
        border-radius: 0px;
    }
}